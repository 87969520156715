import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { ExternalService } from './shared/api/external.service';
import { AuthService } from './shared/services/auth.service';
import { UserApiService } from './user/services/user-api.service';
import { ToastComponent } from "./shared/components/toast/toast.component";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, SpinnerComponent, ToastComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'expertly-web';
  constructor(private authService: AuthService, private userService: UserApiService){
    // externalService.marketplaceData().subscribe(res=>{
    //   if(res.googleAnalyticsId){
    //     this.loadGoogleAnalytics(res.googleAnalyticsId)
    //   }
    // })
    document.documentElement.style.setProperty('--brand-accent-color', '#66F');
  }
  ngOnInit(){
    if (this.authService.isBuyer()) {
      this.userService.getBookmark().subscribe((res) => {
        this.userService.bookmarkedProducts.next(res.map((prod) => prod.productId)) 
      });
    }
  }
  // loadGoogleAnalytics(trackingID: string): void {
  //   let gaScript = document.createElement('script');
  //   gaScript.setAttribute('async', 'true');
  //   gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${ trackingID }`);

  //   let gaScript2 = document.createElement('script');
  //   gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${ trackingID }\');`;

  //   document.documentElement.firstChild?.appendChild(gaScript);
  //   document.documentElement.firstChild?.appendChild(gaScript2);
  // }
}
