import { ApplicationConfig, Injector, Provider } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { FormGroupDirective } from '@angular/forms';
import { LoadingInterceptor } from './shared/interceptors/loading.interceptor';
import { toastInterceptor } from './shared/interceptors/toast.interceptor';


const httpInterceptorProviders: Provider = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true }
];

export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes), provideAnimations(), provideHttpClient(withInterceptorsFromDi(), withInterceptors([toastInterceptor])), httpInterceptorProviders, FormGroupDirective]
};
