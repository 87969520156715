import {
  HttpErrorResponse,
  HttpInterceptorFn,
  HttpResponse,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, finalize, tap, throwError } from 'rxjs';
import { ToastI, ToastService } from '../services/toast.service';

interface ToastConfig{
  path:string,
  method: string,
  toast: ToastI,
  code?: number
}

const successConfig: ToastConfig[] = [
  {
    path:'/timezone',
    method: 'PUT',
    toast:{
      title: 'Saved Successfully!',
      message: 'You have successfully updated your timezone.',
    }
  },
  {
    path:'/language',
    method: 'PUT',
    toast:{
      title: 'Saved Successfully!',
      message: 'You have successfully updated your preferred language(s).',
    }
  }
]


const errorConfig: ToastConfig[] = [
  {
    path:'/api/v1/agency-owner/group',
    method: 'PUT',
    toast:{
      title: 'Upps!',
      message: 'You can not remove provider from this group.',
      type: 'error'
    },
    code: 800
  },
  {
    path:'/api/v1/agency-owner/group',
    method: 'DELETE',
    toast:{
      title: 'Upps!',
      message: 'You can not delete this group.',
      type: 'error'
    },
    code: 799
  },
]

export const toastInterceptor: HttpInterceptorFn = (req, next) => {
  const toastService = inject(ToastService);
  return next(req).pipe(
    tap((event) => {
      if (event instanceof HttpResponse) {
        successConfig.forEach(e=>{
          if (req.url.includes(e.path) && req.method == e.method) {
            toastService.add(e.toast);
          }
        })
      }
    }),
    catchError((err: any) => {
      if (err instanceof HttpErrorResponse) {
        console.log('event', err)
        errorConfig.forEach(e=>{
          if (req.url.includes(e.path) && req.method == e.method) {
            if(!e.code || e.code == err.error.code){
              toastService.add(e.toast);
            }
          }
        })
      }
      return throwError(() => err);
    })
  );
};
